import PropTypes from "prop-types";

import MenuItem from "./menu-item";

const SubMenu = ({ title, items }) => {
    return (
        <div className="perspective relative z-50">
            <button className="menu-btn">
                <span>{title}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 fill-white">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </svg>
            </button>
            <div className="menu">
                <div className="menu-body">
                    <div className="absolute inset-x-0 top-0 flex justify-center">
                        <div className="border-8 border-white border-l-transparent border-t-transparent border-r-transparent" />
                    </div>
                    {items.map((item) => (
                        <MenuItem key={item.url} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

SubMenu.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
};

export default SubMenu;
