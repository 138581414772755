import { useMemo } from "react";

const useQuery = () => useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    searchParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
}, []);

export {
    useQuery
};
