import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useShopState } from "../state/shop-state";
import Quantity from "../components/quantity";

const Home = () => {
    const [image, setImage] = useState("stancebeam-striker1.webp");
    const { prices, quantity, setQuantity } = useShopState();
    const strikerTotal = prices.striker * quantity.striker;
    const grandTotal = useMemo(() => {
        let total = 0;
        Object.keys(quantity).forEach((key) => {
            total += prices[key] * quantity[key];
        });
        return total;
    }, [prices, quantity]);
    const cartEmpty = useMemo(() => {
        let empty = true;
        Object.values(quantity).forEach((value) => {
            if (value > 0) {
                empty = false;
            }
        });
        return empty;
    }, [quantity]);
    const navigate = useNavigate();
    useEffect(() => {
        let i = 1;
        const interval = setInterval(() => {
            if (i > 3) {
                i = 1;
            }
            setImage(`stancebeam-striker${i}.webp`);
            i++;
        }, 3000);
        return () => clearInterval(interval);
    }, []);
    return (
        <div className="max-w-5xl mx-auto px-4 py-12">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                <div className="flex flex-col items-center gap-4">
                    <img
                        src={`/static/images/${image}`}
                        alt="StanceBeam Striker"
                        className="w-full aspect-square object-contain object-center bg-grey-200 rounded" />
                    <div className="flex gap-4">
                        <img
                            src="/static/images/stancebeam-striker1.webp"
                            alt="StanceBeam Striker"
                            className="preview-img"
                            onClick={() => setImage("stancebeam-striker1.webp")}
                            data-active={image === "stancebeam-striker1.webp"} />
                        <img
                            src="/static/images/stancebeam-striker2.webp"
                            alt="StanceBeam Striker"
                            className="preview-img"
                            onClick={() => setImage("stancebeam-striker2.webp")}
                            data-active={image === "stancebeam-striker2.webp"} />
                        <img
                            src="/static/images/stancebeam-striker3.webp"
                            alt="StanceBeam Striker"
                            className="preview-img"
                            onClick={() => setImage("stancebeam-striker3.webp")}
                            data-active={image === "stancebeam-striker3.webp"} />
                    </div>
                </div>
                <div>
                    <h1 className="text-2xl lg:text-3xl font-bold">StanceBeam Striker</h1>
                    <p className="mt-2 text-grey-700">Contains: 1 Sensor, 1 Bat Grip, 1 Charging Cradle, 1 Locking Key</p>
                    <div className="flex items-end gap-2 mt-4">
                        <span className="grow">Price</span>
                        <span className="text-sm line-through decoration-red-500">₹8999.00</span>
                        <span>₹{prices.striker}.00</span>
                    </div>
                    <Quantity
                        className="mt-4"
                        value={quantity.striker}
                        onDecrement={() => {
                            setQuantity((value) => ({
                                ...value,
                                striker: value.striker - 1
                            }));
                        }}
                        onIncrement={() => {
                            setQuantity((value) => ({
                                ...value,
                                striker: value.striker + 1
                            }));
                        }} />
                    <div className="flex justify-between gap-4 mt-8 font-bold">
                        <span>Total</span>
                        <span>₹{strikerTotal}.00</span>
                    </div>
                    <h2 className="mt-8 text-xl font-bold">Accessories (Optional)</h2>
                    <div className="flex items-start gap-4 mt-4">
                        <img src="/static/images/stancebeam-bat-grip.webp" alt="StanceBeam Bat Grip" className="preview-img max-h-32" />
                        <div className="grow">
                            <h3 className="font-bold">StanceBeam Bat Grip</h3>
                            <div className="flex items-end gap-2 mt-4">
                                <span className="grow">Price</span>
                                <span className="text-sm line-through decoration-red-500">₹899.00</span>
                                <span>₹{prices.batgrip}.00</span>
                            </div>
                            <Quantity
                                className="mt-4"
                                value={quantity.batgrip}
                                onDecrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        batgrip: value.batgrip - 1
                                    }));
                                }}
                                onIncrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        batgrip: value.batgrip + 1
                                    }));
                                }} />
                        </div>
                    </div>
                    <div className="flex items-start gap-4 mt-4">
                        <img src="/static/images/tripod.webp" alt="StanceBeam Bat Grip" className="preview-img max-h-32" />
                        <div className="grow">
                            <h3 className="font-bold">Tripod</h3>
                            <div className="flex items-end gap-2 mt-4">
                                <span className="grow">Price</span>
                                <span className="text-sm line-through decoration-red-500">₹1299.00</span>
                                <span>₹{prices.tripod}.00</span>
                            </div>
                            <Quantity
                                className="mt-4"
                                value={quantity.tripod}
                                onDecrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        tripod: value.tripod - 1
                                    }));
                                }}
                                onIncrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        tripod: value.tripod + 1
                                    }));
                                }} />
                        </div>
                    </div>
                    <div className="flex justify-between gap-4 mt-8 font-bold">
                        <span>Grand Total</span>
                        <span>₹{grandTotal}.00</span>
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="btn text brand shadow w-full mt-4 transition-shadow active:shadow-none"
                            onClick={() => navigate("/checkout")}
                            disabled={cartEmpty}>Checkout</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
