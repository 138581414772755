const OrderFailed = () => {
    return (
        <div className="flex flex-col items-center text-center">
            <div className="inline-flex items-center justify-center w-32 h-32 bg-red-500 rounded-full shadow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-24 h-24 fill-white">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
            </div>
            <h1 className="text-2xl lg:text-3xl font-bold mt-4">Order Failed</h1>
            <p className="mt-2">Something went wrong with your payment.</p>
        </div>
    );
};

export default OrderFailed;
