import PropTypes from "prop-types";

const MenuItem = ({ url, title, description }) => {
    return (
        <a href={url} className="menu-item">
            <div className="space-y-1">
                <p className="text-sm font-bold">{title}</p>
                <p className="text-xs">{description}</p>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-[1.875rem] h-[1.875rem] fill-brand">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
            </div>
        </a>
    );
};

MenuItem.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default MenuItem;
