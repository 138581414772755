import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "../hooks";
import { post } from "../api";
import OrderPlaced from "../components/order/placed";
import OrderFailed from "../components/order/failed";

const Order = () => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("failed");
    const navigate = useNavigate();
    const { payment_status, payment_request_id } = useQuery();
    useEffect(() => {
        if (!payment_status || !payment_request_id) {
            return navigate("/", {
                replace: true
            });
        }
        if (payment_status === "COD") {
            setStatus("placed");
            setLoading(false);
            return;
        }
        const confirmOrder = async () => {
            try {
                const data = await post({
                    identity: "promo.service.purchase",
                    txnId: payment_request_id
                });
                if (data.status === "success") {
                    setStatus("placed");
                }
                setLoading(false);
            } catch (error) {
                setStatus("failed");
                setLoading(false);
            }
        };
        confirmOrder();
    }, [navigate, payment_status, payment_request_id]);
    return (
        <div className="flex items-center justify-center min-h-[calc(100vh-5rem)]">
            {loading ? (
                <div className="spinner"></div>
            ) : (
                <div className="w-full max-w-lg mx-auto px-4 py-12">
                    {status === "placed" ? (
                        <OrderPlaced orderId={payment_request_id} />
                    ) : (
                        <OrderFailed />
                    )}
                    <div className="mt-8 p-4 bg-brand bg-opacity-25 rounded-lg text-center">
                        <p>For any further queries regarding your order, contact us at</p>
                        <a href="mailto:contact@stancebeam.com" className="block text-xl font-bold mt-2">contact@stancebeam.com</a>
                        <a href="tel:+919739870008" className="block text-xl font-bold mt-2">+91 97398 70008</a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Order;
