import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import MobileMenu from "./components/mobile-menu";
import Header from "./components/header";
import Home from "./pages/home";
import Checkout from "./pages/checkout";
import Order from "./pages/order";
import CustomOrder from "./pages/custom-order";

const App = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    return (
        <>
            {mobileMenu && (
                <MobileMenu onClose={() => setMobileMenu(false)} />
            )}
            <Header onClickMenu={() => setMobileMenu(true)} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/order" element={<Order />} />
                <Route path="/custom-order" element={<CustomOrder />} />
            </Routes>
        </>
    );
};

export default App;
