import { useState, useEffect } from "react";

import { isEmail, isPhone, isZIPCode } from "../utils";
import TextField from "./text-field";

const ContactInfoForm = ({ className, submitRef }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zip, setZIP] = useState("");
    const [errors, setErrors] = useState({});
    useEffect(() => { }, []);
    submitRef.current = () => {
        const validations = {};
        if (!firstName.trim()) {
            validations.firstName = "First Name is required";
        }
        if (!lastName.trim()) {
            validations.lastName = "Last Name is required";
        }
        if (!email.trim()) {
            validations.email = "Email is required";
        } else if (!isEmail(email)) {
            validations.email = "Email is invalid";
        }
        if (!phone.trim()) {
            validations.phone = "Phone is required";
        } else if (!isPhone(phone)) {
            validations.phone = "Phone is invalid";
        }
        if (!addressLine1.trim()) {
            validations.addressLine1 = "Address Line 1 is required";
        }
        if (!city.trim()) {
            validations.city = "City is required";
        }
        if (!state.trim()) {
            validations.state = "State is required";
        }
        if (!country.trim()) {
            validations.country = "Country is required";
        }
        if (!zip.trim()) {
            validations.zip = "ZIP Code is required";
        } else if (!isZIPCode(zip)) {
            validations.zip = "ZIP Code is invalid";
        }
        setErrors(validations);
        if (Object.keys(validations).length > 0) {
            return null;
        }
        return {
            firstName,
            lastName,
            email,
            phone,
            addressLine1,
            addressLine2,
            city,
            state,
            country,
            zip
        };
    };
    return (
        <div className={`flex flex-col gap-4 ${className}`}>
            <div className="flex gap-4">
                <TextField
                    className="grow"
                    label="First Name"
                    value={firstName}
                    setValue={setFirstName}
                    autoComplete="given-name"
                    error={errors.firstName} />
                <TextField
                    className="grow"
                    label="Last Name"
                    value={lastName}
                    setValue={setLastName}
                    autoComplete="family-name"
                    error={errors.lastName} />
            </div>
            <TextField
                type="email"
                label="Email"
                value={email}
                setValue={setEmail}
                autoComplete="email"
                error={errors.email} />
            <TextField
                type="tel"
                label="Phone"
                maxLength={10}
                value={phone}
                setValue={setPhone}
                autoComplete="tel-national"
                error={errors.phone} />
            <TextField
                label="Address Line 1 (House, Building)"
                value={addressLine1}
                setValue={setAddressLine1}
                autoComplete="address-line1"
                error={errors.addressLine1} />
            <TextField
                label="Address Line 2 (Road, Area)"
                value={addressLine2}
                setValue={setAddressLine2}
                autoComplete="address-line2"
                error={errors.addressLine2} />
            <div className="flex gap-4">
                <TextField
                    className="grow"
                    label="City"
                    value={city}
                    setValue={setCity}
                    error={errors.city} />
                <TextField
                    className="grow"
                    label="State"
                    value={state}
                    setValue={setState}
                    error={errors.state} />
                <TextField
                    className="grow"
                    label="Country"
                    value={country}
                    setValue={setCountry}
                    error={errors.country} />
            </div>
            <TextField
                label="ZIP Code"
                maxLength={6}
                value={zip}
                setValue={setZIP}
                autoComplete="postal-code"
                error={errors.zip} />
        </div>
    );
};

export default ContactInfoForm;
