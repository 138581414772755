import { useState } from "react";

const MobileMenu = ({ onClose }) => {
    const [showProducts, setShowProducts] = useState(false);
    const [showResources, setShowResources] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    return (
        <nav className="bg-gradient-main fixed left-0 z-50 min-h-screen w-full animate-mobile-menu">
            <div className="flex items-center justify-between p-4">
                <a href="https://www.stancebeam.com">
                    <img src="https://www.stancebeam.com/assets/images/logo.svg" alt="StanceBeam Logo" className="h-12" />
                </a>
                <button className="btn w-10 px-0 bg-transparent border border-white rounded text-white" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 fill-white">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                    </svg>
                </button>
            </div>
            <a href="https://www.stancebeam.com" className="block border-t border-white border-opacity-10 p-4 font-bold">Home</a>
            <div className="flex cursor-pointer items-center justify-between border-t border-white border-opacity-10 p-4 font-bold" onClick={() => setShowProducts((value) => !value)}>
                <span>Products</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`w-6 h-6 fill-white transition-transform ${showProducts && "rotate-180"}`}>
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </svg>
            </div>
            {showProducts && (
                <div className="pb-4 text-sm" id="products">
                    <a href="https://www.stancebeam.com/striker-cricket-bat-sensor" className="block px-8 py-3">Striker for Players</a>
                    <a href="https://www.stancebeam.com/cricket-analytics-for-coaches" className="block px-8 py-3">Striker for Coaches</a>
                    <a href="https://www.stancebeam.com/data-and-metrics" className="block px-8 py-3">Data Metrics & Analytics</a>
                    <a href="https://www.stancebeam.com/stancebeam-app" className="block px-8 py-3">StanceBeam App</a>
                </div>
            )}
            <a href="https://www.stancebeam.com/about-us" className="block border-t border-white border-opacity-10 p-4 font-bold">About Us</a>
            <div className="flex cursor-pointer items-center justify-between border-t border-white border-opacity-10 p-4 font-bold" onClick={() => setShowResources((value) => !value)}>
                <span>Resources</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`w-6 h-6 fill-white transition-transform ${showResources && "rotate-180"}`}>
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </svg>
            </div>
            {showResources && (
                <div className="pb-4 text-sm">
                    <a href="https://blog.stancebeam.com" className="block px-8 py-3">Striker for Players</a>
                    <a href="https://blog.stancebeam.com/category/stancebeam-case-study" className="block px-8 py-3">Case Studies</a>
                    <a href="https://blog.stancebeam.com/category/stancebeam-press-and-media" className="block px-8 py-3">Press & Media</a>
                </div>
            )}
            <div className="flex cursor-pointer items-center justify-between border-t border-white border-opacity-10 p-4 font-bold" onClick={() => setShowHelp((value) => !value)}>
                <span>Help</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`w-6 h-6 fill-white transition-transform ${showHelp && "rotate-180"}`}>
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </svg>
            </div>
            {showHelp && (
                <div className="pb-4 text-sm animate-expand" id="help">
                    <a href="https://www.stancebeam.com/help/faqs" className="block px-8 py-3">FAQs</a>
                    <a href="https://www.stancebeam.com/help/getting-started-videos" className="block px-8 py-3">How to Videos</a>
                    <a href="https://www.stancebeam.com/help/contact-us" className="block px-8 py-3">Contact Us</a>
                </div>
            )}
            <a href="https://www.stancebeam.com/store" className="block border-t border-white border-opacity-10 p-4 font-bold">Store</a>
            <div className="border-t border-white border-opacity-10 p-4">
                <a href="https://portal.stancebeam.com/signin" className="btn text outlined flex">Sign In</a>
            </div>
        </nav>
    );
};

export default MobileMenu;
