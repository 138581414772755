import { createContext, useContext, useState, useEffect } from "react";

import { post } from "../api";

const ShopState = createContext();

const ShopStateProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [prices, setPrices] = useState({
        striker: 8999,
        batgrip: 899,
        tripod: 999
    });
    const [quantity, setQuantity] = useState({
        striker: 1,
        batgrip: 0,
        tripod: 0
    });
    const [order, setOrder] = useState();
    useEffect(() => {
        const getPrices = async () => {
            try {
                const data = await post({
                    identity: "promo.service.getproduct"
                });
                const newPrices = {
                    striker: data.price
                };
                data.accessories.forEach(({ accessoryId, price }) => {
                    newPrices[accessoryId] = price;
                });
                setPrices(newPrices);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        getPrices();
    }, []);
    useEffect(() => {
        if (loading) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [loading]);
    const value = {
        prices,
        quantity,
        setQuantity,
        order,
        setOrder
    };
    return (
        <ShopState.Provider value={value}>
            {loading && (
                <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
                    <div className="spinner"></div>
                </div>
            )}
            {children}
        </ShopState.Provider>
    );
};

const useShopState = () => useContext(ShopState);

export {
    ShopStateProvider,
    useShopState
};
