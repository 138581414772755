import { useId } from "react";
import PropTypes from "prop-types";

const TextField = ({ className, type = "text", label, maxLength, value, setValue, error, autoComplete, readOnly = false }) => {
    const id = useId();
    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            <label htmlFor={id} className="block">{label}</label>
            <input
                type={type}
                className="text-field"
                id={id}
                maxLength={maxLength}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                autoComplete={autoComplete}
                readOnly={readOnly} />
            {error && (
                <p className="text-xs text-red-500">{error}</p>
            )}
        </div>
    );
};

TextField.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    error: PropTypes.string,
    autoComplete: PropTypes.string,
    readOnly: PropTypes.bool
};

export default TextField;
