const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
const PHONE_REGEX = /^[0-9]{10}$/;
const PIN_CODE_REGEX = /^[0-9]{6}$/;
const ZIP_CODE_REGEX = /^[0-9]{4,6}$/;

const isEmail = (value) => {
    return EMAIL_REGEX.test(value);
};

const isPhone = (value) => {
    return PHONE_REGEX.test(value);
};

const isPINCode = (value) => {
    return PIN_CODE_REGEX.test(value);
};

const isZIPCode = (value) => {
    return ZIP_CODE_REGEX.test(value);
};

export {
    isEmail,
    isPhone,
    isPINCode,
    isZIPCode
};
