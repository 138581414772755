import SubMenu from "./header/sub-menu";

const products = [
    {
        url: "https://www.stancebeam.com/striker-cricket-bat-sensor",
        title: "Striker for Players",
        description: "Check out how StanceBeam Striker helps you as a player"
    },
    {
        url: "https://www.stancebeam.com/cricket-analytics-for-coaches",
        title: "Striker for Coaches",
        description: "Elevate your coaching with StanceBeam Striker"
    },
    {
        url: "https://www.stancebeam.com/cricket-player-performance-analysis",
        title: "Striker Data Metrics",
        description: "How we give you so accurate data and what it stands for"
    },
    {
        url: "https://www.stancebeam.com/stancebeam-cricket-coaching-app",
        title: "StanceBeam App",
        description: "The app is the soul of the interactions with StanceBeam Striker"
    }
];

const resources = [
    {
        url: "https://blog.stancebeam.com",
        title: "Blog",
        description: "Read what is happening in the universe of cricket from the innovation point of view"
    },
    {
        url: "https://blog.stancebeam.com/category/stancebeam-case-study",
        title: "Case Studies",
        description: "We have changed concept go coaching since we started. Check out few of those well documented case studies"
    },
    {
        url: "https://blog.stancebeam.com/category/stancebeam-press-and-media",
        title: "Press & Media",
        description: "Check out the news about StanceBeam first hand"
    }
];

const help = [
    {
        url: "https://www.stancebeam.com/help/getting-started-videos",
        title: "Getting Started Videos",
        description: "Check out these instructional videos to start seamlessly"
    },
    {
        url: "https://www.stancebeam.com/help/faqs",
        title: "FAQs",
        description: "Stuck Somewhere? We've answered all the questions here"
    },
    {
        url: "https://www.stancebeam.com/help/contact-us",
        title: "Contact Us",
        description: "We are happy to resolve your issues"
    }
];

const Header = ({ onClickMenu }) => {
    return (
        <header className="bg-gradient-main lg:sticky lg:top-0 lg:z-50">
            <div className="mx-auto flex max-w-7xl items-center justify-between p-4">
                <a href="https://www.stancebeam.com">
                    <img src="https://www.stancebeam.com/assets/images/logo.svg" alt="StanceBeam Logo" className="h-12" />
                </a>
                <button className="btn w-10 px-0 bg-transparent border border-white rounded text-white lg:hidden" onClick={onClickMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 fill-white">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                    </svg>
                </button>
                <nav className="hidden items-center gap-8 text-sm lg:flex">
                    <a href="https://www.stancebeam.com" className="inline-block">Home</a>
                    <SubMenu title="Products" items={products} />
                    <a href="https://www.stancebeam.com/about-us" className="inline-block">About Us</a>
                    <SubMenu title="Resources" items={resources} />
                    <SubMenu title="Help" items={help} />
                    <a href="https://www.stancebeam.com/store" className="inline-block">Store</a>
                    <a href="https://portal.stancebeam.com/signin" className="btn text outlined">Sign In</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
