import PropTypes from "prop-types";

const OrderPlaced = ({ orderId }) => {
    return (
        <div className="flex flex-col items-center text-center">
            <div className="inline-flex items-center justify-center w-32 h-32 bg-brand rounded-full shadow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-24 h-24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                </svg>
            </div>
            <h1 className="text-2xl lg:text-3xl font-bold mt-4">Order Placed</h1>
            <p className="mt-2">Our sales team will contact you within 24 hours to confirm your order and provide you the tracking details.</p>
            <p className="mt-8">Your order id is</p>
            <p className="text-lg font-bold">{orderId}</p>
        </div>
    );
};

OrderPlaced.propTypes = {
    orderId: PropTypes.string.isRequired
};

export default OrderPlaced;
