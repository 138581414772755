import PropTypes from "prop-types";

const Quantity = ({ className, value, minimum = 0, onDecrement, onIncrement }) => {
    return (
        <div className={`flex items-start justify-between gap-4 ${className}`}>
            <span>Quantity</span>
            <div className="flex items-center gap-3 bg-grey-50 rounded">
                <button className="increment-btn rounded-l" onClick={onDecrement} disabled={value <= minimum}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 13H5v-2h14v2z" />
                    </svg>
                </button>
                <span>{value}</span>
                <button className="increment-btn rounded-r" onClick={onIncrement}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

Quantity.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    minimum: PropTypes.number,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired
};

export default Quantity;
