import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { post } from "../api";
import Quantity from "../components/quantity";
import ContactInfoForm from "../components/contact-info-form";
import OrderRequest from "../components/order/request";

const CustomOrder = () => {
    const contactRef = useRef();
    const [quantity, setQuantity] = useState({
        striker: 1,
        batgrip: 0
    });
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState();
    const [status, setStatus] = useState();
    const submit = async () => {
        const contact = contactRef.current();
        if (!captcha) {
            setCaptcha("error");
        }
        const hasCaptcha = (typeof captcha === "string") && (captcha !== "error");
        if (contact && hasCaptcha) {
            setLoading(true);
            try {
                const selectedProducts = [
                    {
                        id: "striker",
                        quantity: quantity.striker,
                        amount: 0,
                        type: "product"
                    }
                ];
                if (quantity.batgrip > 0) {
                    selectedProducts.push({
                        id: "batgrip",
                        quantity: quantity.batgrip,
                        amount: 0,
                        type: "accessory"
                    });
                }
                const data = await post({
                    identity: "promo.service.prepurchase",
                    amount: 0,
                    discountPrice: 0,
                    quantity: 1,
                    userProfile: {
                        shippingAddress: contact,
                        billingAddress: contact
                    },
                    paymentMode: "international",
                    selectedProducts
                });
                setStatus(data.status);
                setLoading(false);
            } catch (error) {
                alert(error.message);
                setLoading(false);
            }
        }
    };
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[calc(100vh-5rem)]">
                <div className="spinner"></div>
            </div>
        );
    }
    if (status) {
        return (
            <div className="flex items-center justify-center min-h-[calc(100vh-5rem)]">
                <OrderRequest success={status === "success"} />
            </div>
        );
    }
    return (
        <div className="max-w-5xl mx-auto px-4 py-12">
            <h1 className="text-3xl font-bold">Order Request</h1>
            <p className="mt-2">Fill out this form and our team will contact you</p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-8">
                <div className="space-y-4">
                    <h2 className="text-2xl font-bold">Products</h2>
                    <div className="flex items-center gap-4">
                        <img src="/static/images/stancebeam-striker1.webp" alt="StanceBeam Striker" className="preview-img lg:max-h-32" />
                        <div className="grow">
                            <h3 className="text-xl font-bold">StanceBeam Striker</h3>
                            <Quantity
                                className="mt-2"
                                value={quantity.striker}
                                minimum={1}
                                onIncrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        striker: value.striker + 1
                                    }));
                                }}
                                onDecrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        striker: value.striker - 1
                                    }));
                                }} />
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <img src="/static/images/stancebeam-bat-grip.webp" alt="StanceBeam Bat Grip" className="preview-img lg:max-h-32" />
                        <div className="grow">
                            <h3 className="text-xl font-bold">StanceBeam Bat Grip</h3>
                            <Quantity
                                className="mt-2"
                                value={quantity.batgrip}
                                onIncrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        batgrip: value.batgrip + 1
                                    }));
                                }}
                                onDecrement={() => {
                                    setQuantity((value) => ({
                                        ...value,
                                        batgrip: value.batgrip - 1
                                    }));
                                }} />
                        </div>
                    </div>
                </div>
                <div className="">
                    <h2 className="text-2xl font-bold">Contact Information</h2>
                    <ContactInfoForm className="mt-4" submitRef={contactRef} />
                    <div className="flex flex-col items-center mt-8">
                        <ReCAPTCHA
                            sitekey="6LdZ6ucfAAAAACStpO7WhSs3AoDNk1XLOyNs1rHP"
                            onChange={setCaptcha} />
                        {captcha === "error" && (
                            <p className="text-sm text-red-500 mt-1">Please verify that you are not a robot.</p>
                        )}
                    </div>
                    <button className="btn text brand shadow w-full mt-8" onClick={submit} disabled={loading}>
                        {loading ? (
                            <div className="spinner"></div>
                        ) : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomOrder;
