import { useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { useShopState } from "../state/shop-state";
import { post } from "../api";
import AddressForm from "../components/address-form";

const names = {
    striker: "StanceBeam Striker",
    batgrip: "StanceBeam Bat Grip",
    tripod: "Tripod"
};

const Checkout = () => {
    const shippingFormRef = useRef();
    const billingFormRef = useRef();
    const [differentBillingAddress, setDifferentBillingAddress] = useState(false);
    const [paymentMode, setPaymentMode] = useState("card");
    const [captcha, setCaptcha] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { prices, quantity, setOrder } = useShopState();
    const orderSummary = useMemo(() => {
        const products = [];
        let total = 0;
        Object.keys(quantity).forEach((key) => {
            const subtotal = prices[key] * quantity[key];
            total += subtotal;
            if (subtotal > 0) {
                products.push({
                    id: key,
                    name: names[key],
                    quantity: quantity[key],
                    subtotal
                });
            }
        });
        return {
            products,
            total
        };
    }, [quantity, prices]);
    const placeOrder = async () => {
        const shippingAddress = shippingFormRef.current();
        let billingAddress = shippingAddress;
        if (differentBillingAddress) {
            billingAddress = billingFormRef.current();
        }
        if (!captcha) {
            setCaptcha("error");
        }
        const hasCaptcha = (typeof captcha === "string") && (captcha !== "error");
        if (shippingAddress && billingAddress && hasCaptcha) {
            setLoading(true);
            setOrder({
                shippingAddress,
                billingAddress,
                paymentMode,
                products: orderSummary.products,
                amount: orderSummary.total
            });
            try {
                const selectedProducts = orderSummary.products.map(({ id, quantity, subtotal }) => ({
                    id,
                    type: id === "striker" ? "product" : "accessory",
                    quantity,
                    amount: subtotal
                }));
                const data = await post({
                    identity: "promo.service.prepurchase",
                    amount: orderSummary.total,
                    discountPrice: 0,
                    quantity: 1,
                    userProfile: {
                        shippingAddress,
                        billingAddress
                    },
                    paymentMode,
                    selectedProducts
                });
                setLoading(false);
                console.log(data);
                if (data.status === "success") {
                    if (data.paymentMode === "card") {
                        window.location.href = data.longurl;
                        return;
                    }
                    return navigate(`/order?payment_status=COD&payment_request_id=${data.txnId}`, {
                        replace: true
                    });
                }
                setError("Something went wrong! Please try again.");
            } catch (error) {
                setError("Something went wrong! Please try again.");
                setLoading(false);
            }
        }
    };
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 max-w-5xl mx-auto px-4 py-12">
            <div>
                <h2 className="text-xl lg:text-2xl font-bold">Shipping Address</h2>
                <AddressForm className="mt-4" submitRef={shippingFormRef} />
                <div className="flex items-center gap-4 mt-8">
                    <input
                        type="checkbox"
                        className="form-checkbox border-black text-brand focus:ring-brand"
                        id="different-billing-address"
                        onChange={({ target }) => setDifferentBillingAddress(target.checked)} />
                    <label htmlFor="different-billing-address">Use Different Billing Address</label>
                </div>
                {differentBillingAddress && (
                    <>
                        <h2 className="mt-8 text-xl lg:text-2xl font-bold">Billing Address</h2>
                        <AddressForm className="mt-4" submitRef={billingFormRef} />
                    </>
                )}
            </div>
            <div>
                <h2 className="text-xl lg:text-2xl font-bold">Order Summary</h2>
                <div className="flex items-center justify-between gap-4 h-10 mt-4 px-4 bg-grey-200 rounded">
                    <span>Product</span>
                    <span>Subtotal</span>
                </div>
                {orderSummary.products.map((product) => (
                    <div key={product.id} className="flex justify-between gap-4 mt-4 px-4">
                        <span>{product.name} X {product.quantity}</span>
                        <span>₹{product.subtotal}.0</span>
                    </div>
                ))}
                <div className="flex items-center justify-between gap-4 h-10 mt-4 px-4 bg-grey-200 rounded font-bold">
                    <span>Total</span>
                    <span>₹{orderSummary.total}.0</span>
                </div>
                <div className="mt-8">
                    <span>Payment Mode</span>
                    <div className="flex gap-4 mt-2">
                        <div className="radio-wrapper" onClick={() => setPaymentMode("card")}>
                            <div className="radio" data-active={paymentMode === "card"} />
                            <span>Pay Now</span>
                        </div>
                        {quantity.striker > 0 && (
                            <div className="radio-wrapper" onClick={() => setPaymentMode("cod")}>
                                <div className="radio" data-active={paymentMode === "cod"} />
                                <span>Cash on Delivery</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col items-center mt-8">
                    <ReCAPTCHA
                        sitekey="6LdZ6ucfAAAAACStpO7WhSs3AoDNk1XLOyNs1rHP"
                        onChange={setCaptcha} />
                    {captcha === "error" && (
                        <p className="text-sm text-red-500 mt-1">Please verify that you are not a robot.</p>
                    )}
                </div>
                <div className="mt-8">
                    <button className="btn text brand shadow w-full transition-shadow active:shadow-none" onClick={placeOrder} disabled={loading}>
                        {loading ? (
                            <div className="spinner" />
                        ) : "Place Order"}
                    </button>
                    {error && (
                        <p className="text-red-500 text-center mt-4">{error}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Checkout;
