const PARSE_APP_ID = "AA4071F5A30CA6525E2AED19ABA3F77AF72F90592412DBB707D55BCE7A86F3FDDBAB591E34D6014E6B8E1CC2F2ACD57E9D3731CD97F509EC65CDF7AC0A779897";

const BASE_URL = "https://strikerapiprod.azurewebsites.net/web/promo";

const post = async (body) => {
    const response = await fetch(BASE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": PARSE_APP_ID
        },
        body: JSON.stringify({
            ...body,
            _ApplicationId: PARSE_APP_ID
        })
    });
    if (response.ok) {
        return response.json();
    } else {
        throw new Error(response.statusText);
    }
};

export {
    post
};
